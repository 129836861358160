import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { isNil } from 'lodash'
import pluralize from 'pluralize'
import { getRecaptchaToken } from 'recaptcha'
import { IMAGE } from 'shared_server_client/constants'
import { TemplateModule, TemplateModuleType } from 'shared_server_client/types/email_layout'
import * as Utils from 'shared_server_client/utils'
import * as Constants from './constants'

interface FindModuleItem {
  index: number,
  module: TemplateModule,
}

const sameModuleType = (m1: TemplateModule, m2: TemplateModule): boolean => {
  // Same type, and same sectionId (if there is )
  if ( m1.type === m2.type ) {
    const config1 = m1.moduleConfig || {}
    const config2 = m2.moduleConfig || {}
    return (config1.sectionId || -1) === (config2.sectionId || -1)
  } else {
    return false
  }
}
export const getMatchingModules = (templateModules: TemplateModule[], module: TemplateModule): FindModuleItem[] => {
  const foundModules: FindModuleItem[] = []
  templateModules.forEach((element, index) => {
    if ( sameModuleType(element, module) ) {
      foundModules.push({index, module: element})
    }
  })
  return foundModules
}

export const getModuleIndex = (templateModules: TemplateModule[], type: string, sequence: number) => {
  return templateModules.findIndex((x) => x.type === type && x.sequence === sequence)
}

export const getModuleConfigValue = (module: TemplateModule, config: string, defaultValue: string = '') => {
  return module && module.moduleConfig && !isNil(module.moduleConfig[config]) ? module.moduleConfig[config] : ''
}

export const updateStaticImageProperty = (existingStaticImages, id, property, value) => {
  return {
    ...existingStaticImages,
    [id]: {...existingStaticImages[id], [property]: value},
  }
}

export const updateStaticImages = (existingStaticImages, id, newImage) => {
  return {
    ...existingStaticImages,
    [id]: newImage,
  }
}

export const getStaticImageAttribute = (props: any, id: number, key: string, defaultValue: any = null) => {
  const { static_images } = props
  return static_images[id] && static_images[id][key]
  ? static_images[id][key]
  : defaultValue
}

export const getStaticTextAttribute =
  (props: any, staticTextType: string, key: string, defaultValue: any = null): any => {
    const { static_texts } = props
    return static_texts[staticTextType] && static_texts[staticTextType][key]
    ? static_texts[staticTextType][key]
    : defaultValue
}

export const getStaticTextStylesAttribute =
  (props: any, staticTextType: string, key: string, defaultValue: any = null): any => {
    const { static_texts } = props
    if (static_texts && static_texts[staticTextType] && static_texts[staticTextType].styles) {
      const styles = JSON.parse(static_texts[staticTextType].styles)
      return styles[key]
      ? styles[key]
      : defaultValue
    } else {
      return defaultValue
    }
}

export const updateStaticTextStylesAttribute =
  (props, staticTextType: string, attribute, value) => {
      const { static_texts } = props
      const existingStaticText = static_texts ? static_texts[staticTextType] : {}
      const existingStaticTextStyle = existingStaticText && existingStaticText.styles
      ? JSON.parse(existingStaticText.styles)
      : {}
      const newStaticTextStyle = {
        ...existingStaticTextStyle,
        [attribute]: value,
      }
      return {
        ...existingStaticText,
        styles: JSON.stringify(newStaticTextStyle),
      }
}

export const updateStaticTextAttribute =
  (existingStaticTexts, staticTextType: string, attribute, value) => {
    const existingStaticText = existingStaticTexts ? existingStaticTexts[staticTextType] : {}
    return {
      ...existingStaticText,
      [attribute]: value,
    }
}

export const updateStaticTextAttributes = (
  existingStaticTexts,
  staticTextType: string,
  changes,
) => {
    const existingStaticText = existingStaticTexts ? existingStaticTexts[staticTextType] : {}
    return {
      ...existingStaticText,
      ...changes,
    }
}

export const isScheduledModuleById = (templateModules: TemplateModule[], moduleType: string, id: number): boolean => {
    const moduleIndex = getModuleIndex(templateModules, moduleType, id)
    const module = templateModules[moduleIndex]
    return module.moduleConfig.isScheduled
}

export const isScheduledModule = (templateModule: TemplateModule): boolean => {
  return templateModule.moduleConfig.isScheduled
}

export const hasScheduledModule = (templateModules: TemplateModule[] = []): boolean => {
  return !!templateModules.filter((tm) => tm.moduleConfig.isScheduled).length
}

export const staticImageDBNameMapToUIType = (dbName: string): string => {
  if (dbName.startsWith('Banner')) {
    return IMAGE
  } else {
    return 'twoColumnImage'
  }
}

export const isClickableModule = (type: string): boolean => {
  return ['image', 'twoColumnImage', 'leadText'].includes(type)
}

export const mergeTokens =
(text: string, article: any, communityName: string, firstName: string, date: Date): string => {
  if (text) {
    const noArticle = {
      description: '',
      title: '',
    }
    const recommendedArticle = article || noArticle
    const title = (recommendedArticle.title || '').split('|')[0]
    const cappedDescription = Utils.capText(
      (recommendedArticle.description || '').split('|')[0], 100, Utils.CapTextStrategy.WORD_CAP)
    const description =
      `${cappedDescription.text}${cappedDescription.isCapped ? '...' : ''}`

    return text.replace(Constants.RECOMMENDED_TITLE, title)
      .replace(Constants.FEATURED_TITLE, title)
      .replace(Constants.TITLE, title)
      .replace(Constants.DESCRIPTION, description)
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      .replace(Constants.DATE, ('' + date).slice(0, 16))
      .replace(Constants.COMMUNITY_NAME, communityName)
      .replace(Constants.USER_FIRST_NAME, firstName)
  } else {
    return ''
  }
}

export const getArticlePreviewError = (minArticlesCount: number) => {
  return 'Oh no, you don\'t have enough content. \
  You need to have at least &nbsp;' +
  pluralize('article', (minArticlesCount || 4), true) +
  '&nbsp; to send a test of your newsletter.'
}

export const sendEmail = (community: string, email: string, payload: any, date: Date = null) => {
  return getRecaptchaToken('sendNewsletter').then((token) => {
    const newPayload = {
      ...payload,
      recaptcha: token,
    }
    const url: string = AjaxWrapper.getServerUrl() + '/' + community + '/send-newsletter'
    return AjaxWrapper.ajax(url, HttpMethod.POST, newPayload)
      .then((newData) => {
        return true
      })
  })
}

export const resetTemplateModules = (templateModules: TemplateModule[]) => {
  resetArticleModules(templateModules)
  resetSectionModules(templateModules)
  fixDuplicateSequences(templateModules)
}

const resetArticleModules = (templateModules: TemplateModule[] = []) => {
  let consumed = 0
  templateModules.filter((module) => module.type === TemplateModuleType.article).forEach((module) => {
      module.moduleConfig.start = consumed
      consumed = consumed + (module.moduleConfig.count || 0)
      module.moduleConfig.end = module.moduleConfig.end ? consumed : null
  })
  templateModules.filter((module) => module.type === TemplateModuleType.restOfArticles).forEach((module) => {
      module.moduleConfig.start = consumed
      if (module.moduleConfig.end) {
        delete module.moduleConfig.end
      }
  })
}

const resetSectionModules = (templateModules: TemplateModule[]) => {
  templateModules.filter((module) => module.type === TemplateModuleType.section).forEach((module) => {
      module.moduleConfig.start = 0
      module.moduleConfig.end = module.moduleConfig.count ? module.moduleConfig.count : 1
  })
}

interface ModuleSequence {
  module_type: TemplateModuleType,
  sequence: number,
}
const fixDuplicateSequences = (templateModules: TemplateModule[]) => {
  const sequences: ModuleSequence[] = []
  templateModules.forEach((module) => {
    if (sequenceExists(module, sequences)) {
      // eslint-disable-next-line prefer-spread
      const existingSequence = Math.max.apply(Math, templateModules
        .filter((x) => x.type ===  module.type).map((x) => x.sequence))
      module.sequence =  existingSequence && existingSequence !== -Infinity  ? existingSequence + 1 : 1
    }
    const sequence: ModuleSequence = {
      module_type: module.type,
      sequence: module.sequence,
    }
    sequences.push(sequence)
  })
}

const sequenceExists = (module: TemplateModule, sequences: ModuleSequence[]): boolean => {
  return  sequences.filter((sequence) => sequence.module_type === module.type
    && sequence.sequence === module.sequence).length > 0
}
