import * as RexConstants from '../rex/constants'

export const TEXT_MODAL_KEY = 'generatedTextModal'
export const SUMMARY_MODAL_KEY = 'generatedSummaryModal'

export const GENERATE_TEXT_ACTION = 'GENERATE_TEXT_ACTION'
export const SAVE_TEXT_ACTION = 'SAVE_TEXT_ACTION'

export const baseEmptyState = {
  content: '',
  emoji: RexConstants.EmojiDropdownOptions[0],
  error: '',
  isSaving: false,
  language: RexConstants.LanguageDropdownOptions[0],
  length: RexConstants.LengthDropdownOptions[0],
  prompt: '',
  promptType: RexConstants.PromptTypeDropdownOptions[0],
  title: '',
  tone: RexConstants.ToneDropdownOptions[0],
  url: '',
  versionDisplay: 0,
  versionGenerate: 0,
  versions: [''],
}
