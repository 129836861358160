import * as Router from '../../router'
import * as Constants from './constants'
import * as Types from './types'

export const reducer = (state: Types.State = Constants.emptyState, action: Types.SetMessageAction) => {
  switch (action.type) {
    case Constants.SHOW_FLASH_ERROR:
      return {
        color: Constants.FLASH_ERROR_COLOR,
        message: action.message || Constants.DEFAULT_ERROR_MESSAGE,
      }
    case Constants.SHOW_FLASH_WARNING:
      return {
        color: Constants.FLASH_WARNING_COLOR,
        message: action.message || Constants.DEFAULT_ERROR_MESSAGE,
      }
    case Constants.SHOW_CUSTOM_MESSAGE:
      return {
        color: action.color || Constants.FLASH_WARNING_COLOR,
        custom: true,
        message: action.message || Constants.DEFAULT_ERROR_MESSAGE,
      }
    case Constants.SHOW_FLASH_MESSAGE:
      return action.message ? {
        color: Constants.FLASH_MESSAGE_COLOR,
        message: action.message,
      } : Constants.emptyState
    case Constants.CLEAR_FLASH_MESSAGE:
      if (state.custom) {
        // this is a custom message, let custom handler handles it
        return {
          ...state,
          custom: false, // toggle it so that when custom handler can clear the message
        }
      } else {
        return Constants.emptyState
      }
    case Router.LOCATION_CHANGE:
      return Constants.emptyState
    default:
      return state
  }
}
