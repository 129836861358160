import React from 'react'
import { DownArrow } from '../icons/downarrow'

import './styles.css'

export const ExpandoButton = ({ children, expanded = false, ...props }) => {
  return (
    <button className="expando" {...props}>
      {children}
      {expanded ? null : <DownArrow />}
    </button>
  )
}
