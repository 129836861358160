import * as React from 'react'
import * as Modals from 'shared/modals'
import { ChatPayload, GenerationType } from 'shared_server_client/types/generative_ai'
import * as RexConstants from '../rex/constants'
import * as RexTypes from '../rex/types'
import './_styles.scss'
import { BaseGeneratedTextComponent } from './base'
import * as Constants from './constants'
import * as Types from './types'

interface Props extends Modals.ModalComponentProps {
  description: string,
  title: string,
  url: string,
}

export class GeneratedSummaryModalComponent
  extends BaseGeneratedTextComponent<Props, Types.State> {

    public static defaultProps = {
      ...BaseGeneratedTextComponent.defaultProps,
      saveText: 'Use It!',
    }

    public static key: string = Constants.SUMMARY_MODAL_KEY
    private static selectorTypes: RexTypes.SelectorType[] = RexConstants.ALL_SELECTOR_TYPES

  constructor(props: Props) {
    super(props, GeneratedSummaryModalComponent.key, GeneratedSummaryModalComponent.selectorTypes)
    this.state = Constants.baseEmptyState
  }

  protected renderInitialInputs = () => {
    return <div>
      <div className="first-row-container article-title">
        <h5>Article Title</h5>
        <span>{this.props.title}</span>
      </div>
      <div className="first-row-container article-description">
        <h5>Original Summary</h5>
        <div className="description-wrap">{this.props.description}</div>
      </div>
    </div>
  }

  protected responsesTitle = (): string => 'Alternate Summary'

  protected getGenerationType(): GenerationType {
    return GenerationType.SUMMARY
  }

  protected buildChatPayload(): ChatPayload {
    return {
      ...super.buildChatPayload(),
      content: this.props.description,
      promptType: 'article summary',
      title: this.props.title,
      url: this.props.url,
    }
  }

}
