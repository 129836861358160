import { GridCell } from '@progress/kendo-react-grid'
import React from 'react'

// TODO: favicons not working ?

export class PublishedCell extends GridCell {
  public render() {
    return  <td>
    { this.props.dataItem.published &&
      <span className="published">
        <i className="fas fa-check"></i>
      </span>}
    </td>
  }
}
