//// GENERIC NavigatorBase class for UX
//// Properties:
////  Sections: PanelBarOption[] - sets up the various sections, refer to info on PanelBarOption class
////  BaseRoute: string - the base route to build up from, the routes from the sections will be added to this
////  Title: string - title to put into the panel bar
////  SubTitle: string - sub-title to put into the panel bar
////  HeaderIcon: React.Component - optional component to display in the header of the panel bar
////  ItemIcon: React.Component - optional component to display in the panel bar for each item
////  HeaderCssClass: string - CSS class to style the wrapper of the navigator component
////  ContentDivCssClass: string - CSS class to style the div that contains the components for each section
////  onChange: optional event handler for containers of this component to listen for when nav changes
import * as GA from 'google-analytics'
import { Component } from 'react'

import { PanelBarOption } from '../panel-bar/types'

export interface GenericNavigatorSelectedItemProps {
  selectedItem: PanelBarOption,
}

export interface GenericNavigatorProps extends GenericNavigatorSelectedItemProps {
  baseRoute: string,
  contentDivCssClass?: string,
  headerCssClass?: string,
  headerIcon?: any,
  push: any,
  subTitle: string,
  sections: PanelBarOption[],
  title: string,
}

export interface GenericNavigatorState {
  selectedItem: PanelBarOption,
}

export abstract class GenericNavigatorComponent extends Component<GenericNavigatorProps, GenericNavigatorState> {
  private _lastItem: PanelBarOption

  constructor(props: GenericNavigatorProps) {
    super(props)
    this._lastItem = props.selectedItem
    this.state = {
      selectedItem: props.selectedItem,
    }
    this._updateComponentState(true)
  }

  public componentDidUpdate() {
    this._updateComponentState(false)
  }

  protected blankIfNull(str: string): string {
    return (str == null ? '' : str)
  }

  private _children: any[] = null;
  protected clearChildren() {
    this._children = null; // wipe this out in case we have kids from last render
  }

  protected saveChild(newChild: any) {
    if (!this._children) {
      this._children = []
    }
    this._children[this._children.length] = newChild
    return newChild
  }

  protected handleNav(e: PanelBarOption) {
    if (e.route !== this.props.selectedItem.route) {
      // change our internal state and show/hide the appropriate component
      // this is automatically bound to the visibility via the JSX lower
      // in this file that requires selectedItem to match their key
      this.setState({
        ...this.state,
        selectedItem: e,
      }, () => {
        if ( e.event ) {
          this.context.store.dispatch({type: GA.SEND_EVENT_4, event: e.event })
        }
      })
      this.props.push(
        `${this.props.baseRoute}${(e.route.trim().length > 0 ? `/${e.route}` : '')}`)

      // notify the visible component that it is being shown
      if (this._children) {
        const visibleComponent: any = this._children.find((item) => item.type.displayName === e.component.displayName)
        try {
          // wrap in a try block just in case something screwy happens with react
          if (visibleComponent && visibleComponent.type.WrappedComponent && visibleComponent.type.WrappedComponent.prototype.onShow) {
            visibleComponent.type.WrappedComponent.prototype.onShow()
          }
        } catch (ex) {
          // supress, we don't care...
          // eslint-disable-next-line no-console
          console.log(ex)
        }
      }
    }
  }

  protected shouldShowComponent(item: PanelBarOption): boolean {
    return item.component && (this.props.selectedItem.route === item.route.toLowerCase())
  }

  private _updateComponentState(bDirect: boolean) {
    const itemVal: PanelBarOption = this.props.selectedItem
    if (itemVal !== this._lastItem) {
      // only update state if we got a different value from outside of the component
      // compared to the last props.selectedItem we got
      const newState = {
        selectedItem: this.props.sections.find((item: PanelBarOption) =>
                        (item.route.toLowerCase() === itemVal.route.toLowerCase())),
      }
      if (bDirect) {
        this.state = newState
      } else {
        this.setState(newState)
      }
    }
    this._lastItem = itemVal
  }

}
