import * as GA from 'google-analytics'
import * as ReduxObservable from 'redux-observable'
import {filter, map} from 'rxjs/operators'
import * as Constants from './constants'

const isOpenAction = (action): boolean => {
  return action.data && (
    action.type.includes(`${Constants.TEXT_MODAL_KEY}_OPEN`) ||
    action.type.includes(`${Constants.SUMMARY_MODAL_KEY}_OPEN`)
  )

}

const onOpen: ReduxObservable.Epic = (action$, state$, { history, user }) => {
  return action$
    .pipe(
      filter((action) => isOpenAction(action)),
      map((action) => ({
        name: GA.EventNames.ChatGPTOpen,
        payload: {
          ...action.data,
          key: action.type.split('_').slice(-2)[0],
        },
      })),
      map((event: GA.Event4) => ({type: GA.SEND_EVENT_4, event})),
    )
}

const onModalAction: ReduxObservable.Epic = (action$, state$, { history, user }) => {
  return action$.ofType(Constants.GENERATE_TEXT_ACTION, Constants.SAVE_TEXT_ACTION)
    .pipe(
      map((action) => action.data),
      map((event: GA.Event4) => ({type: GA.SEND_EVENT_4, event})),
    )
}

export const epic = ReduxObservable.combineEpics(
  onModalAction,
  onOpen,
)
