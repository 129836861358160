import * as Types from './types'
export const CLEAR_FLASH_MESSAGE = 'CLEAR_FLASH_MESSAGE'
export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE'
export const SHOW_FLASH_ERROR = 'SHOW_FLASH_ERROR'
export const SHOW_FLASH_WARNING = 'SHOW_FLASH_WARNING'
export const SHOW_CUSTOM_MESSAGE = 'SHOW_CUSTOM_MESSAGE'

export const FLASH_WARNING_COLOR = 'warning'
export const FLASH_ERROR_COLOR = 'danger'
export const FLASH_MESSAGE_COLOR = 'success'

export const DEFAULT_ERROR_MESSAGE = 'Whoops! Something went wrong.'

export const emptyState: Types.State = {
  color: '',
  custom: false,
  message: '',
}
