import { UpgradeAnchorLink } from 'components/link'
import React from 'react'
import { UpgradeComponent, UpgradeComponentProps } from '../upgrade/component';
import './styles.css'
import { ContactToSupportComponent, ContactToSupportComponentProps } from '../upgrade/contact-to-rasa'

export enum FeatureUnavailableComponentName {
  AnchorLink,
  Upgrade,
  ContactSupport,
}

export interface FeatureUnavailableProps {
  displayMessage?: string,
  source: string,
  loadComponent?: FeatureUnavailableComponentName,
  upgradeComponentProps?: UpgradeComponentProps,
  contactToSupportProps?: ContactToSupportComponentProps,
}

export const FeatureUnavailableComponent = (props: FeatureUnavailableProps) => {
  let componentToLoad = <UpgradeAnchorLink source={props.source}>
    <div className="feature-unavailable">
      {props.displayMessage ? props.displayMessage : 'Upgrade your plan to access' }
    </div>
  </UpgradeAnchorLink>

  switch (props.loadComponent) {
    case FeatureUnavailableComponentName.Upgrade:
      componentToLoad = <UpgradeComponent {...props.upgradeComponentProps}/>
      break
    case FeatureUnavailableComponentName.ContactSupport:
      componentToLoad = <ContactToSupportComponent {...props.contactToSupportProps} />
      break
  }

  return componentToLoad
}
