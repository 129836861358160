import * as GA from 'google-analytics'
import * as Constants from './constants'
import * as Types from './types'

export const plain2html = (text) => {
  text = (text || '');
  return text
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/\t/g, '    ')
      .replace(/\r\n|\r|\n/g, '<br />')
}

export const generateTextEvent = (key: string, state: Types.State) => {
  return {
    type: Constants.GENERATE_TEXT_ACTION,
    data: {
      name: GA.EventNames.ChatGPTGenerate,
      payload: {
        emojis: state.emoji.key,
        key,
        length: state.length.key,
        prompt: state.prompt,
        promptType: state.promptType.key,
        tone: state.tone.key,
      },
    },
  }
}

export const saveTextEvent = (key: string, action: string, state: Types.State) => {
  return {
    type: Constants.SAVE_TEXT_ACTION,
    data: {
      name: GA.EventNames.ChatGPTSave,
      payload: {
        action,
        key,
        text: state.versions[state.versionDisplay],
      },
    },
  }
}
