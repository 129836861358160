//// GENERIC NavigatorBase class for UX
//// Properties:
////  Sections: PanelBarOption[] - sets up the various sections, refer to info on PanelBarOption class
////  BaseRoute: string - the base route to build up from, the routes from the sections will be added to this
////  Title: string - title to put into the panel bar
////  SubTitle: string - sub-title to put into the panel bar
////  HeaderIcon: React.Component - optional component to display in the header of the panel bar
////  ItemIcon: React.Component - optional component to display in the panel bar for each item
////  HeaderCssClass: string - CSS class to style the wrapper of the navigator component
////  ContentDivCssClass: string - CSS class to style the div that contains the components for each section
////  onChange: optional event handler for containers of this component to listen for when nav changes
import React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'

import { MobilePanelBar } from '../../panel-bar/mobile/mobile'

import { GenericNavigatorComponent } from '../component'

import { ReducedMobilePanelBar } from 'components/panel-bar/mobile/reduced_mobile'
import { PanelBarOption } from 'components/panel-bar/types'
import './_styles.scss'

class MobileNavigatorComponent extends GenericNavigatorComponent {

  public render() {
    this.clearChildren()
    return <div className={this.blankIfNull(this.props.headerCssClass) + ' mobile-navigator-wrap'}>
      <MobilePanelBar
        onChange={(e: PanelBarOption) => {this.handleNav(e)}}
        itemList={this.props.sections}
        selectedItem={this.props.selectedItem}
        title = {this.props.title}
        subTitle = {this.props.subTitle}
        headerIcon = {this.props.headerIcon}
      />
      <div className={this.blankIfNull(this.props.contentDivCssClass) + ' mobile-navigator-contentpane clearfix'}>
        {
          this.props.sections.map((item) => this.shouldShowComponent(item) &&
                                              <div key={item.name}>
                                                {this.saveChild(React.createElement(item.component, {}))}
                                              </div>)
        }
      </div>
    </div>
  }

}

export const MobileNavigator = connect(
  null,
  {
    push: Router.push,
  },
  )(MobileNavigatorComponent)

class ReducedMobileNavigatorComponent extends GenericNavigatorComponent {

    public render() {
      this.clearChildren()
      return <div className={this.blankIfNull(this.props.headerCssClass) + ' mobile-navigator-wrap'}>
        <ReducedMobilePanelBar
          onChange={(e: PanelBarOption) => {this.handleNav(e)}}
          itemList={this.props.sections}
          selectedItem={this.props.selectedItem}
          title = {this.props.title}
          subTitle = {this.props.subTitle}
          headerIcon = {this.props.headerIcon}
        />
        <div className={this.blankIfNull(this.props.contentDivCssClass) + ' mobile-navigator-contentpane clearfix'}>
          {
            this.props.sections.map((item) => this.shouldShowComponent(item) &&
                                                <div key={item.name}>
                                                  {this.saveChild(React.createElement(item.component, {}))}
                                                </div>)
          }
        </div>
      </div>
    }

  }

  export const ReducedMobileNavigator = connect(
    null,
    {
      push: Router.push,
    },
    )(ReducedMobileNavigatorComponent)
