import * as Constants from './constants'
import * as Types from './types'

export const clearFlash = () => ({type: Constants.CLEAR_FLASH_MESSAGE})
export const showFlashError = (message: Types.Message = null) => ({
  message,
  type: Constants.SHOW_FLASH_ERROR,
})

export const showFlashMessage = (message: Types.Message = null) => ({
  message,
  type: Constants.SHOW_FLASH_MESSAGE,
})

export const showFlashWarning = (message: Types.Message = null) => ({
  message,
  type: Constants.SHOW_FLASH_WARNING,
})

export const showCustomMessage = (message: Types.Message = null, color: string = null) => ({
  color,
  message,
  type: Constants.SHOW_CUSTOM_MESSAGE,
})
